<template>
  <section class="documentColumn">
    <div v-if="collection">
      <div
          v-if="canEdit && isEditable"
          class="uk-button uk-width-1-1 uk-background-primary"
      >
        <CreateCollectionDocument
            :collection-id="collection.repoId"
            :document-encoding="encoding"
            :document-name="newDocumentName"
            :name-is-required="nameIsRequired"
            :default-document-content="defaultDocumentContent"
            :dataType="dataType"
            :create-button-label="createButtonLabel"
            :relay-event="relayEvent"
            @create="onCreate"
        ></CreateCollectionDocument>
      </div>
      <div
          class="uk-padding-remove-vertical"
          v-if="documents.length"
      >
        <DocumentList
            :collection-id="collection.repoId"
            :show-sortable="isSortable"
            :isFullWidth="isFullWidth"
            :textIsResponsive="textIsResponsive"
            :aliasAttributeName="aliasAttributeName"
            :isInverted="isInverted"
        ></DocumentList>
      </div>
      <div
          v-else
          class="uk-placeholder"
      >
        No {{ noDocumentsTypeLabel || assetAlias || 'document' }} here yet
      </div>
    </div>
    <vLoading
        v-else
        :resource-name="dataType"
    ></vLoading>
  </section>
</template>

<script>
import { decapitalize } from "../../../../../lib/stringFunctions";
import CreateCollectionDocument from "@/components/service/document/CreateCollectionDocument";
import DocumentList from "@/components/service/collection/DocumentList"
import {useCollection} from "@/components/use/serviceAssets/useCollection"
import {usePermissions} from "@/components/use/usePermissions"
import {computed, watch} from "vue";
import {useStore} from "vuex";

export default {
  name: "DocumentColumnLayout",
  emits: ['create'],
  components: {
    CreateCollectionDocument,
    DocumentList,
  },
  props: {
    aliasAttributeName: {
      type: String,
      default: '',
    },
    createButtonLabel: {
      type: String,
      default: 'Create Document'
    },
    collectionName: {
      type: String,
      required: true,
    },
    defaultDocumentContent: {
      type: String,
      default: 'empty'
    },
    dataType: {
      type: String,
      required: true
    },
    encoding: {
      type: String,
      default: 'markdown',
    },
    textIsResponsive: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isSortable: {
      type: Boolean,
      default: true
    },
    nameIsRequired: {
      type: Boolean,
      default: false,
    },
    newDocumentName: {
      type:String,
      default: ''
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    relayEvent: {
      type: Boolean,
      default: false,
    },
    showImmutableNamesWarning: {
      type: Boolean,
      default: false,
    },
    noDocumentsTypeLabel: {
      type:String,
      default: 'Document'
    },
  },
  setup(props, {emit}) {
    const store = useStore()
    const { loadCollection} = useCollection()
    const {canEdit} = usePermissions()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const documents = computed(() => Object.values(store.state.document)
          .filter(d => d.parentRepoId === collection.value?.repoId)
    )

    watch(service, service => {
      if (service)     loadCollection({collectionName: props.collectionName})
    }, {immediate: true})

    const onCreate = createArgs => emit('create', createArgs)

    return {
      assetAlias: decapitalize(props.dataType),
      canEdit,
      collection,
      documents,
      onCreate,
    }
  }
}
</script>

<style lang="stylus" scoped>
.actions
  padding 0

</style>
