<template>
  <div
      v-show="sequence.length && sequence.length > 1"
      class="uk-margin uk-grid-small uk-child-width-1-1"
      uk-tooltip="title: Order the headlines by dragging them"
      uk-grid
  >
    <Draggable
        class="dragArea"
        :list="sequence"
        @change="updateSequence"
    >
      <transition-group>
        <DocumentSortableItem
            v-for="(repoId, index) in sequence"
            :key="index"
            :collection-id="collectionId"
            :document-id="repoId"
            :aliasAttributeName="aliasAttributeName"
        />
      </transition-group>
    </Draggable>
  </div>
</template>

<script>
import {VueDraggableNext} from 'vue-draggable-next'
import DocumentSortableItem from './DocumentSortableItem'
import {useCollection} from '@/components/use/serviceAssets/useCollection'
import {computed} from "vue"
import {useVersion} from "@u/serviceAssets/useVersion";
import {useStore} from "vuex";

export default {
  name: 'SortableDocuments',
  emits: ['sorted'],
  components: {
    DocumentSortableItem,
    Draggable: VueDraggableNext,
  },
  props: {
    aliasAttributeName: {
      type: String,
      default: '',
    },
    arrayCollectionId: {
      type: String,
      default: '',
    },
    arrayDocumentId: {
      type: String,
      default: '',
    },
    collectionId: {
      type: String,
      required: true
    },
    isInvertedList: {
      type: Boolean,
      default: false
    },
    relayEvents: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const {getDocuments, putDocumentSequence} = useCollection()
    const {putVersionContent} = useVersion()
    const sequence = computed(() => {
      let sequence = getDocuments(props.collectionId)?.map(doc => doc.repoId) ?? []
      if (props.arrayCollectionId && props.arrayDocumentId) sequence = [...store.state.document[props.arrayDocumentId].content]
      if (props.isInvertedList) sequence.reverse()
      return sequence
    })

    const updateSequence = () => {
      let value = sequence.value
      if (props.isInvertedList) value.reverse()
      if (props.arrayCollectionId && props.arrayDocumentId) {
        putVersionContent({
          serviceId: store.state.serviceName,
          collectionId: props.arrayCollectionId,
          documentId: props.arrayDocumentId,
          value
        })
            .then(() => {
              if (props.relayEvents) emit('sorted', value)
            })

      } else putDocumentSequence(props.collectionId, value)
          .then(() => {
            if (props.relayEvents) emit('sorted', value)
          })
    }

    return {
      sequence,
      updateSequence,
    }
  },
}
</script>

<style scoped>
</style>
