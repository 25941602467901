<template>
  <div class="uk-container uk-padding-small uk-padding-remove-horizontal uk-margin">
    <h1 v-if="title" class="uk-heading" v-html="title"></h1>
    <div v-if="collection && canEdit && showSortable">
      <SortableDocuments
          :collection-id="collection.repoId"
          :aliasAttributeName="aliasAttributeName"
          :is-inverted-list="isInverted"
      ></SortableDocuments>
    </div>
    <div
        v-if="collection"
        class="documents"
        :class="{docGrid: !isFullWidth, responsiveText: textIsResponsive}"
    >
      <TransitionGroup name="v">
        <DocumentLayout
            v-for="(document, index) in documents"
            :key="index"
            :collection-id="collection.repoId"
            :document-id="document.repoId"
            :document-name="document.name"
        ></DocumentLayout>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import SortableDocuments from './sortable/SortableDocuments'
import {useCollection} from '@/components/use/serviceAssets/useCollection'
import {usePermissions} from "@/components/use/usePermissions";
import {computed, watch} from "vue";
import {useStore} from "vuex";

export default {
  name: 'DocumentList',
  components: {
    SortableDocuments,
  },
  props: {
    aliasAttributeName: {
      type: String,
      default: '',
    },
    collectionId: {
      type: String,
      required: true
    },
    collectionName: {
      type: String,
      default: ''
    },
    textIsResponsive: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    showSortable: {
      type: Boolean,
      default: true,
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions()
    const {loadCollection} = useCollection()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const documents = computed(() => {
      let documents = []
      const sequence = collection.value?.documentSequence
      if (sequence?.length) documents = sequence
          .map(id => store.state.document[id])
          .filter(item => item)
      else documents = Object.values(store.state.document).filter(d => d.parentRepoId === props.collectionId)

      if (props.isInverted) documents.reverse()

      return documents
    })

    watch(service, service => {
      if (service) {
        loadCollection({
          collectionName: props.collectionName,
          collectionId: props.collectionId
        })
      }
    }, {immediate: true})

    return {
      canEdit,
      collection,
      documents,
      store
    }
  },
}
</script>

<style lang="stylus" scoped>

.documents
  display grid
  grid-row-gap 1rem

.v-enter-from
.v-leave-to
  opacity 0

.v-leave-from
.v-enter-to
  opacity 1

:not(:last-child)
  padding-bottom 0

@media only screen and (min-width: 1340px)

  .docGrid
    display grid
    grid-template-columns 1fr 1fr
    grid-column-gap 1rem

  .documents.responsiveText
    column-count 2

</style>
