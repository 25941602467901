<template>
  <div class="documentColumn uk-margin">
    <div v-if="collection">
      <CreateCollectionColumnsDocument
          v-if="canEdit"
          :collection-id="collection.repoId"
          :columnVariants="columnVariants"
          :dataType="dataType"
      ></CreateCollectionColumnsDocument>
      <Collection
          v-if="documents.length"
          :collection-id="collection.repoId"
          :create-button-label="`Add ${ assetType }`"
          :show-sortable="true"
          class="paddedColumn"
      ></Collection>
      <div
          v-else
          class="uk-placeholder"
      >
        No teasers here yet
      </div>
    </div>
    <vLoading
        v-else
        resource-name="Items"
    ></vLoading>
  </div>
</template>

<script>
import CreateCollectionColumnsDocument
  from "@/components/service/document/md/columnDocuments/CreateCollectionColumnsDocument";
import {useCollection} from "@/components/use/serviceAssets/useCollection"
import {computed, ref, watch} from "vue";
import Collection from "@/components/service/collection/DocumentList";
import {usePermissions} from "@/components/use/usePermissions";
import {useStore} from "vuex";

export default {
  name: "ColumnDocumentColumnLayout",
  components: {
    Collection,
    CreateCollectionColumnsDocument,
  },
  props: {
    assetType: {
      type: String,
      default: 'Document'
    },
    collectionName: {
      type: String,
      default: 'teasers',
    },
  },
  setup(props) {
    const store = useStore()
    const { loadCollection } = useCollection()
    const {canEdit} = usePermissions()
    const encoding = 'markdownArray'
    const dataType = 'MarkdownColumns'
    const editorShown = ref('create-single-column')
    const showDeleteDocumentDialog = ref(false)
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const documents = computed(() => Object.values(store.state.document).filter(d => d.parentRepoId === collection.value?.repoId))
    const columnVariants = computed(() => [
      {
        label: `Single-Column-${ props.assetType }`,
        columns: 1
      },
      {
        label: `Double-Column-${ props.assetType }`,
        columns: 2
      }
    ])

    watch(service, service => {
      if (service) loadCollection({collectionName: props.collectionName})
    }, {immediate: true})

    return {
      canEdit,
      collection,
      columnVariants,
      documents,
      dataType,
      editorShown,
      encoding,
      showDeleteDocumentDialog,
    }
  }
}
</script>

<style lang="stylus" scoped>
.actions
  display grid
  grid-template-columns 1fr 1fr
  grid-column-gap 1rem
</style>
