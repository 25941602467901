<template>
  <div
      :class="{'uk-padding-remove-right': canEdit}"
      class="uk-margin markdownColumns"
  >
    <vOffCanvas :off-canvas-id="id_markdownHowTo">
      <vEditingDocumentsInfo></vEditingDocumentsInfo>
    </vOffCanvas>
    <article
        :class="{editing: showInput}"
        :style="documentStyle"
    >
      <div class="content">
        <div
            v-if="showInput"
            :style="columnsStyle"
            class="columns"
        >
          <vMarkdownInput
              v-for="(value, index) in columns"
              :key="index"
              :value="value"
              @change="(newValue) => updateColumns(index, newValue)"
          ></vMarkdownInput>
        </div>
        <div
            :style="columnsStyle"
            class="columns"
        >
          <vMarkdown
              v-for="(value, index) in columns"
              :key="index"
              :md="value"
              class="out"
          ></vMarkdown>
        </div>
      </div>
      <div
          v-if="canEdit"
          class="uk-iconnav uk-iconnav-vertical"
      >
        <vOffCanvasButton
            :not-a-button="true"
            :off-canvas-id="id_markdownHowTo"
            @click="toggleMarkdownHelp"
        >
          <IconHelpCircle></IconHelpCircle>
        </vOffCanvasButton>
        <div class="toggle">
          <IconLeadPencil
              v-if="canEdit && !showInput"
              class="primary"
              title="edit content"
              @click="showInput = true"
          ></IconLeadPencil>
          <IconEyeOff
              v-if="canEdit && showInput"
              class="primary"
              title="hide editor"
              @click="hideEditingComponents"
          ></IconEyeOff>
        </div>
        <DeleteDocumentDialog @delete-document-confirmed="onDelete"></DeleteDocumentDialog>
      </div>
    </article>
  </div>
</template>

<script>
/*
  display markdown editor
  emit change and delete events
 */
import DeleteDocumentDialog from "@/components/service/document/DeleteDocumentDialog";
import vEditingDocumentsInfo from "@/components/service/document/md/vEditingDocumentsInfo"
import vMarkdownInput from "@/components/service/document/md/vMarkdownInput"
import {computed, ref} from "vue";
import {useMarkdownColumns} from "@/components/use/serviceAssets/documentEditors/MarkdownColumns";
import {useStore} from "vuex";

export default {
  /*
  load Document
  split content into columns
  layout columns
  update document content
  delete document
 */

  name: "MarkdownColumns",
  emits: ['show-delete-dialog'],
  components: {
    DeleteDocumentDialog,
    vEditingDocumentsInfo,
    vMarkdownInput,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const {canEdit, columnCount, columns, deleteVersion, updateColumns} = useMarkdownColumns(props)

    const hideEditingComponents = () => {
      showInfo.value = false
      showInput.value = false
    }
    const showInfo = ref(false)
    const showInput = ref(false)
    const id_markdownHowTo = ref('mdHelp')
    const toggleMarkdownHelp = () => showInfo.value = !showInfo.value

    const documentStyle = computed(() => {
      let gridStyle = '1fr'
      if (canEdit.value) gridStyle += ' 2rem'
      return {
        gridTemplateColumns: gridStyle
      }
    })
    const columnsStyle = computed(() => {
      const style = {
        gridTemplateColumns: '1fr '.repeat(columnCount.value).trim(),
      }
      if (columnCount.value) style.gridColumnGap = '1rem'
      return style
    })

    const emitShowDeleteDialog = () => emit('show-delete-dialog', props.documentId)
    const onDelete = () => {
      deleteVersion({
        serviceId: store.state.serviceName,
        collectionId: props.collectionId,
        documentId: props.documentId
      })
    }

    return {
      columnsStyle,
      canEdit,
      columns,
      emitShowDeleteDialog,
      updateColumns,
      onDelete,
      documentStyle,
      hideEditingComponents,
      id_markdownHowTo,
      showInfo,
      showInput,
      toggleMarkdownHelp,
    }
  }
}
</script>

<style lang="stylus" scoped>
article,
.columns
  width 100%
  display grid

</style>
