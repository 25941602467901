<template>
  <div
      class="markdownEditor"
  >
    <div
        :class="{editing: editorVisible}"
        class="documentEditor"
    >
      <vMarkdownInput
          v-if="editorVisible"
          class="in"
          :value="markdown"
          @change="onChange"
          @abort="onAbort"
      ></vMarkdownInput>
      <vMd
          class="out"
          :md="markdown"
          v-bind="$attrs"
      ></vMd>
    </div>
    <div
        v-show="error"
        class="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import vMd from '@c/utilities/vMd.vue'
import vMarkdownInput           from '@c/service/document/md/vMarkdownInput.vue'
import { computed, ref } from 'vue'

export default {
  name: 'vMarkdownEditor',
  emits: ['change', 'revert'],
  components: {
    vMarkdownInput,
    vMd,
  },
  props: {
    markdown: {
      type: String,
      default: ''
    },
    showEditor: {
      type: Boolean,
      default: false
    },
  },
  setup(props, {emit}) {
    const error = ref('')
    const originalValue = ref('')
    const payload = ref(props.markdown)
    const editorVisible = computed(() => props.showEditor)
    const setOriginalValue = () => originalValue.value = payload.value
    const showError = errorMessage => { error.value = errorMessage }
    const onChange = newValue => emit('change', newValue)
    const onAbort = () => emit('revert', originalValue.value)

    return {
      editorVisible,
      error,
      showError,
      payload,
      onAbort,
      onChange,
      setOriginalValue,
    }
  },
  mounted() {
    this.setOriginalValue()
  },
}
</script>

<style scoped>
.markdownEditor {
  text-align: left;
}

.error {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #fd7c7c;
  color: black;
  font-family: monospace;
  white-space: pre-wrap;
}
</style>
