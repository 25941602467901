<template>
  <div
    :class="{ active: hover }"
    class="markdown out"
    @mouseleave="hover = false"
    @mouseover="hover = true"
  >
    <div
        v-if="!md"
        ref="sandbox"
    ></div>
    <vMd
        :md="md"
    ></vMd>
</div>
</template>

<script>
import { ref }         from 'vue'

export default {
  name: 'RawMarkdown',
  props: {
    md: {
      type: String,
      required: true
    }
  },
  setup () {
    const hover = ref(false)
    return {
      hover,
    }
  },
  mounted () {
    this.$nextTick(this.compileTemplate)
  },
}
</script>

<style scoped lang="stylus">

  @media(hover: hover) and (pointer: fine)

    .markdown
      width 100%
      *
        width 100%

    .markdown:hover > .anchor
      opacity 1!important

</style>
