<template>

  <article
      class="markdownIt uk-width-1-1"
      :class="{editing: isEditMode && showInput}"
      :style="gridStyle"
  >
    <div class="column">
      <Transition name="slide-fade">
        <MarkdownInput
            v-if="isEditMode && enableEditor && showInput"
            :value="editorValue"
            class="markdownIt-in uk-width-1-1"
            :class="{isEditable: isEditMode}"
            @change="onChange"
            @abort="onAbort"
        ></MarkdownInput>
      </Transition>
      <Markdown
          class="markdownIt-out uk-width-1-1"
          :html="true"
          :breaks="true"
          :anchor="anchorOptions"
          :linkify="true"
          :abbr="true"
          :sub="true"
          :sup="true"
          :mark="true"
          :source="value"
      ></Markdown>
    </div>
    <div
        v-if="isEditMode && enableEditor"
        class="flex flex-wrap"
    >
      <div
          class="toggle flex flex-wrap justify-between"
      >
        <IconLeadPencil
            v-if="!showInput"
            class="triggersEditor"
            title="edit content"
            @click="onShow"
        ></IconLeadPencil>
        <IconEyeOff
            v-if="showInput"
            title="hide editor"
            @click="onHide"
        ></IconEyeOff>
      </div>
    </div>
  </article>

</template>

<script>
import MdAnchor from 'markdown-it-anchor'
import Markdown from "vue3-markdown-it";
import MarkdownInput from "@c/service/document/md/vMarkdownInput";
import {computed, ref} from "vue";
import {v4 as uuid} from 'uuid'
import {popSuccess} from "@/components/use/popups/popupMessages"
import {copyTextToClipboard} from "@/lib/copyTextToClipboard";
import {useUser} from "@u/useUser";

export default {
  name: "MarkdownIt",
  emits: ['abort', 'change', 'pristine'],
  components: {
    Markdown,
    MarkdownInput,
  },
  props: {
    editorId: {
      type: String,
      default: uuid()
    },
    enableEditor: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const {user:isEditMode} = useUser()
    const showInput = ref(false)
    const editorValue = ref(props.value)
    const gridStyle = computed(() => {
      let columns = ['1fr']
      if (isEditMode.value && props.enableEditor) columns.push('2rem')
      return {
        gridTemplateColumns: columns.join(' ')
      }
    })

    const anchorOptions = {
      callback: (token) => {
        const elHeading = document.getElementById(token.attrs.find(([name]) => name === 'id')[1])
        if (elHeading) {
          const linkChar = '&#x1f517;'
          let elLinkSymbol = elHeading.childNodes[1]
          if (!elLinkSymbol) {
            elLinkSymbol = document.createElement('span')
            elLinkSymbol.innerHTML = linkChar
            elLinkSymbol.classList.add('header-link-symbol')
            elLinkSymbol.classList.add('uk-margin-small-left')
            elLinkSymbol.classList.add('cursor-pointer')
            elHeading.appendChild(elLinkSymbol)
            elHeading.classList.add(elHeading.tagName.toLowerCase())
            elHeading.addEventListener('click', e => {
              e.preventDefault()
              const url = location.href + '#' + elHeading.getAttribute('id')
              copyTextToClipboard(url)
              popSuccess(`Permalink copied to your clipboard: ${url}.`)
            })
          }
        }
      },
      slugify: string => encodeURIComponent(String(string).trim()),
      permalink: MdAnchor.permalink.headerLink() // https://github.com/valeriangalliat/markdown-it-anchor/blob/master/permalink.js
    }

    const onAbort = value => {
      if (value !== props.value) emit('abort', value)
    }
    const onChange = value => {
      if (value !== props.value) emit('change', value)
    }
    const onHide = () => showInput.value = false
    const onShow = () => showInput.value = true

    return {
      anchorOptions,
      editorValue,
      gridStyle,
      isEditMode,
      onAbort,
      onChange,
      onHide,
      onShow,
      showInput,
    }
  }
}
</script>

<style scoped>
.markdownIt {
  min-height: 1rem;
}

article {
  display: grid;
  grid-template-columns: 1fr 2em;
  grid-column-gap: 0.5rem;
  padding: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

</style>
