<template>
  <div class="mdIntro info uk-background-primary">
    <h2>Editing Markdown Documents</h2>
    <p>
      Documents support the following content types:
    </p>
    <ul>
      <li>plain text</li>
      <li>
        <a
href="https://markdown-it.github.io/"
target="_blank"
>markdown</a>
      </li>
      <li>
        <a
href="https://www.learn-html.org/en/Basic_Elements"
target="_blank"
>html</a>
      </li>
    </ul>
    <hr>
    <p>
      You can link to any document headline.
    </p>
    <p>
      Click <IconLinkPlus /> next to any document heading to copy its <em>url</em> to the clipboard.
    </p>
    <p>
      Create a link to the heading:
    </p>
    <pre>[visible text](<em>url</em>)</pre>
    <hr>
    <p>
      Upload images <a :href="imageRepoUrl" target="_blank">to our media file repository</a>.
    </p>
    <p>Render an image like so:</p>
    <pre>![image](<em>image url</em>)</pre>
  </div>
</template>

<script>
import config from '../../../../../../config.js'

const {
MEDIA_REPO_ORGANIZATION,
MEDIA_REPO_NAME
} = config

export default {
    name: "EditingDocumentsIntro",
    setup() {
      return {
        imageRepoUrl: `https://github.com/${MEDIA_REPO_ORGANIZATION}/${MEDIA_REPO_NAME}`
      }
    }
  }
</script>

<style scoped lang="stylus">
  .mdIntro
    padding 1rem

  li
    cursor pointer

  .info
    background-color #ebf0f4

  a
    text-decoration underline!important

</style>
