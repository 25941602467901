<template>
  <div class="createCollectionDocument">
    <div class="actions ">
      <div
          class="uk-width-1-1"
          @click="createDocument(documentName)"
      >
        <RawMarkdown :md="createButtonLabel"></RawMarkdown>
      </div>
    </div>
    <NameNewDocumentDialog
        v-if="showNameEditor && !documentName"
        :show-immutable-name-warning="showImmutableNamesWarning"
        asset-plural-name="documents"
        :assetType="dataType"
        @new-name-ready="onNameReady"
    ></NameNewDocumentDialog>
  </div>
</template>

<script>
import NameNewDocumentDialog from "@/components/utilities/NameNewDocumentDialog";
import RawMarkdown from "@/components/service/document/md/RawMarkdown";
import {computed, ref} from "vue";
import {useCollection} from "@/components/use/serviceAssets/useCollection";
import {usePermissions} from "@/components/use/usePermissions";
import {handleError} from "@/lib/handleError";
import { useStore } from "vuex";
import types from '@/components/use/serviceAssets/documentEditors/documentEditors'

export default {
  name: "CreateCollectionDocument",
  emits: ['create'],
  components: {
    NameNewDocumentDialog,
    RawMarkdown,
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    createButtonLabel: {
      type: String,
      default: 'Create New Document'
    },
    defaultDocumentContent: {
      type: String,
      default: ''
    },
    documentName: {
      type: String,
    },
    dataType: {
      type: String,
      required: true,
    },
    nameIsRequired: {
      type: Boolean,
      default: false
    },
    relayEvent: {
      type: Boolean,
      default: false
    },
    showImmutableNamesWarning: {
      type: Boolean,
      default: false
    },
  },
  setup(props, {emit}) {
    const store = useStore()
    const {canEdit} = usePermissions()
    const { createCollectionDocument} = useCollection()
    const collection = computed(() => store.state.collection[props.collectionId])
    const showNameEditor = ref(false)
    const defaultContent = ref(types?.[props.dataType]?.getDocumentTemplate(props.defaultDocumentContent).content || props.defaultDocumentContent)
    const onNameReady = name => {
      const documentName = name || props.documentName
      if (props.nameIsRequired && !documentName) showNameEditor.value = !showNameEditor.value
      else createDocument(documentName)
    }
    const createDocument = documentName => {
      verifyDocumentName(documentName)
      const {encoding} = types[props.dataType]

      const createArgs = {
        serviceId: store.state.serviceName,
        collectionId: props.collectionId,
        documentName,
        dataType: props.dataType,
        encoding,
        content: defaultContent.value,
      }
      verifyEditorComponentName(props.dataType)
      if (props.relayEvent) emit('create', createArgs)
      else createCollectionDocument(createArgs)
    }
    const verifyDocumentName = documentName => {
      documentName = documentName || props.documentName
      !documentName && props.nameIsRequired && handleError(`Cannot create nameless document. documentName is required`)
    }
    const verifyEditorComponentName = dataType => {
      if (!dataType) {
        handleError(`Cannot create document. dataType is required`)
      }
      return dataType
    }
    return {
      canEdit,
      collection,
      createDocument,
      onNameReady,
      showNameEditor,
    }
  }
}
</script>

<style scoped>

</style>
