<template>
  <div class="grid">
    <DraggableListItem>
      <DocumentAlias
          :collection-id="collectionId"
          :document-id="documentId"
          :aliasAttributeName="aliasAttributeName"
      ></DocumentAlias>
    </DraggableListItem>
  </div>
</template>

<script>
import DocumentAlias from "@/components/service/collection/sortable/DocumentAlias";
import DraggableListItem from './DraggableListItem'
import {useDocument} from "@/components/use/serviceAssets/useDocument";
import {useMarkdownDeeplinks} from "@/components/use/markdown/useMarkdownDeeplinks";
import {usePermissions} from "@/components/use/usePermissions";
import {computed} from "vue";
import {SERVICE_ACRONYM} from '../../../../../../config'
import {useStore} from "vuex";

export default {
  name: "DocumentSortableItem",
  components: {
    DocumentAlias,
    DraggableListItem,
  },
  props: {
    aliasAttributeName: {
      type: String,
      default: '',
    },
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {getDocumentUrl, goToDocument} = useDocument(props)
    const {copyDeepLinkUrlToClipboard} = useMarkdownDeeplinks(props)
    const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))
    const documentUrl = computed(() => getDocumentUrl(SERVICE_ACRONYM, props.collectionId, props.documentId))

    return {
      canEdit,
      copyDeepLinkUrlToClipboard,
      document,
      documentUrl,
      goToDocument,
    }
  },
}
</script>

<style scoped>

</style>
