<template>
    <textarea
        ref="input"
        :style="textareaStyle"
        :value="value"
        class="vMarkdownInput markdown-area uk-width-1-1"
        @input="onChange"
        @blur="onAbort"
        @keydown.esc="onAbort"
        @keydown.tab.prevent="onTab"
    ></textarea>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import {isNumber} from "@/lib/getVariableType";

export default {
  name: 'vMarkdownInput',
  emits: ['change', 'abort'],
  props: {
    minLines: {
      type: Number,
      default: 1,
      validate: newValue => isNumber(newValue) && newValue > 0
    },
    value: {
      default: '',
      type: String
    },
  },
  setup(props, {emit}) {
    const input = ref(null)
    const originalValue = ref(props.value)
    const textareaStyle = computed(() => {
      return {minHeight: `${props.minLines || 1}rem`}
    })

    const onAbort = () => emit('abort', originalValue)
    const onChange = event => emitChange(event.target.value)
    const onTab = () => {
      const el = input.value
      let start = el.selectionStart
      let end = el.selectionEnd
      el.value = el.value.substring(0, start) + "\t" + el.value.substring(end)
      el.selectionEnd = el.selectionStart = start + 1
    }

    const emitChange = newValue => {
      emit('change', newValue)
      resize()
    }

    const resize = () => {
      const textarea = input.value
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight + 12}px`
    }

    onMounted(() => resize())

    return {
      input,
      onAbort,
      onChange,
      onTab,
      textareaStyle,
    }
  },
}
</script>

<style scoped>
.markdownInput {
  resize: vertical !important;
  min-height: 1rem;
  min-width: 100%;
  padding: .5rem;
  border-radius: 4px;
  white-space: pre-wrap;
  text-wrap: normal;
}
</style>
