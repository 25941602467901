<template>
  <div
      v-if="document"
      class="document"
  >
    <DocumentLayout
        :collection-id="collection.repoId"
        :document-id="document.repoId"
        @show-delete-dialog="showDeleteDocumentDialog = true"
    ></DocumentLayout>
  </div>

  <div v-else-if="collection && canEdit">
    <div class="uk-card uk-card-default uk-card-small uk-card-body">
      <div class="notification">
        <h3>Create missing document <strong>{{ documentName }}</strong> now</h3>
      </div>
      <div
          :uk-tooltip="`${SERVICE_ACRONYM}/${collectionName}/${documentName} does not yet exist. Create it now`"
          class="uk-button uk-button-primary"
      >
        <CreateCollectionDocument
            :collection-id="collection.repoId"
            :document-name="documentName"
            dataType="MarkdownContent"
            :default-document-content="defaultDocumentContent"
            create-button-label="Create __Document__"
        ></CreateCollectionDocument>
      </div>
    </div>
  </div>

  <div v-else>{{ document }}
    <div class="uk-placeholder">
      Missing document "{{collectionName}}/{{ documentName }}"
    </div>
  </div>
</template>

<script>
import CreateCollectionDocument from "@/components/service/document/CreateCollectionDocument";
import DocumentLayout from "@/components/service/document/DocumentLayout";
import {useDocument} from "../../../use/serviceAssets/useDocument";
import {usePermissions} from "@/components/use/usePermissions";
import {computed, watch} from "vue";
import {SERVICE_ACRONYM } from '../../../../../../config'
import {useStore} from "vuex";

export default {
  name: "PermanentDocument",
  components: {
    CreateCollectionDocument,
    DocumentLayout,
  },
  props: {
    collectionName: {
      type: String,
      required: true,
    },
    documentName: {
      type: String,
      required: true,
    },
    createIfNotExists: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {loadDocument} = useDocument(props)
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))

    const defaultDocumentContent = `## Page Text
write content in markdown here...`

    const service = computed(() => store.state.service[SERVICE_ACRONYM])
    watch(
        service,
        isReady => {
          if (isReady) {
            loadDocument({
              createIfNotExists: props.createIfNotExists && canEdit.value,
              // collectionId: collection.value?.repoId,
              collectionName: props.collectionName,
              documentName: props.documentName,
              dataType: 'MarkdownContent',
              defaults: {
                payload: defaultDocumentContent
              }
            }, 'PermanentDocument')
          }
        },
        {immediate: true}
    )

    return {
      canEdit,
      collection,
      defaultDocumentContent,
      document,
      SERVICE_ACRONYM,
    }
  },
}
</script>

<style scoped>

</style>
