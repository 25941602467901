<template>
  <div class="documentArray uk-container uk-padding-small uk-padding-remove-horizontal">
    <h2 v-if="title" class="uk-heading" v-html="title"></h2>
    <div v-if="documentArrayDocument && paragraphsCollection && canEdit && showSortable">
      <SortableDocuments
          :collection-id="paragraphsCollection.repoId"
          :arrayCollectionId="documentArraysCollection.repoId"
          :arrayDocumentId="documentArrayDocument.repoId"
          :aliasAttributeName="aliasAttributeName"
      ></SortableDocuments>
    </div>
    <div v-if="paragraphsCollection" class="documents">
      <TransitionGroup>
        <ParagraphsLayout
            class="uk-margin"
            v-for="(document, index) in documents"
            :key="index"
            :collection-id="paragraphsCollection.repoId"
            :document-id="document.repoId"
            :relayDeleteEvent="true"
            @delete="onDelete"
        ></ParagraphsLayout>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import SortableDocuments from '../collection/sortable/SortableDocuments'
import {useCollection} from '@/components/use/serviceAssets/useCollection'
import {usePermissions} from "@/components/use/usePermissions";
import {computed, watch} from "vue";
import {useStore} from "vuex";
import {useDocument} from "@u/serviceAssets/useDocument";
import ParagraphsLayout from "@c/utilities/paragraphComponents/ParagraphsLayout";

export default {
  /*
    loads docuemnt of ids to load list of documents
   */
  name: 'DocumentArrays',
  emits: ['delete'],
  components: {
    ParagraphsLayout,
    SortableDocuments,
  },
  props: {
    aliasAttributeName: {
      type: String,
      default: '',
    },
    documentId: {
      type: String,
      default: '',
    },
    showSortable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const {canEdit} = usePermissions()
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument()
    // const { putVersionContent} = useVersion()
    const service = computed(() => store.state.service[store.state.serviceName])
    const documentArraysCollection = computed(() => Object.values(store.state.collection).find(c => c.name === 'documentArrays'))
    // paragraphs: document contents
    const paragraphsCollection = computed(() => Object.values(store.state.collection).find(c => c.name === 'paragraphs'))
    // document array: sequence and id of module paragraphs
    const documentArrayDocument = computed(() => store.state.document[props.documentId])
    const documents = computed(() => {
      let documents = []
      if (documentArrayDocument.value) documents = documentArrayDocument.value?.content.map(id => store.state.document[id])
      return documents
    })

    watch(service, service => {
      if (service) {
        loadCollection({collectionName: 'documentArrays'})
        loadCollection({collectionName: 'paragraphs'})
      }
    }, {immediate: true})

    watch(documentArraysCollection, collection => {
      if (collection?.repoId && props.documentId) {
        loadDocument({
          collectionId: collection.repoId,
          documentId: props.documentId,
        }, 'DocumentArrays.vue')
      }
    }, {immediate: true})

    const onDelete = id => emit('delete', id)

    return {
      canEdit,
      documentArraysCollection,
      documentArrayDocument,
      documents,
      onDelete,
      paragraphsCollection,
      store
    }
  },
}
</script>

<style lang="stylus" scoped>
.v-enter-from
.v-leave-to
  opacity 0

.v-leave-from
.v-enter-to
  opacity 1

:not(:last-child)
  padding-bottom 0

</style>
