<template>
  <div class="documentAlias">
    <div
        v-if="alias && Array.isArray(alias)"
        class="aliases"
    >
      <RawMarkdown
          v-for="(entry, index) in alias"
          :key="index"
          :md="entry"
      ></RawMarkdown>
    </div>
    <RawMarkdown
        v-else
        :md="alias || 'error generating alias'"
    ></RawMarkdown>
  </div>
</template>

<script>
import RawMarkdown from "@/components/service/document/md/RawMarkdown";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {computed} from "vue";

export default {
  name: "DocumentAlias",
  components: {
    RawMarkdown,
  },
  props: {
    aliasAttributeName: {
      type: String,
      default: '',
    },
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {getPayloadAlias} = useVersion()
    const payloadAlias = computed(() => getPayloadAlias(props.documentId, props.aliasAttributeName))
    const alias = computed(() => {
      let value = payloadAlias.value
      if (Array.isArray(payloadAlias))
        value = payloadAlias.value
          .map(itemAlias => {
            const maxItemAliasLength = 25
            const hasTooManyChars = itemAlias.length >= maxItemAliasLength - 3
            const rendersAsImage = itemAlias.startsWith('![')
            if (hasTooManyChars && !rendersAsImage) {
              itemAlias = itemAlias.substring(0, maxItemAliasLength - 3) + '...'
            }
            return itemAlias
          })
      return value
    })
    return {
      alias,
    }
  }
}
</script>

<style lang="stylus">
.documentAlias
  .aliases
    display flex
    justify-content space-between

  h1, h2, h3, h4, h5, h6, p
    font-size 18px !important
    font-weight bolder
    margin-bottom: 0

  img
    max-height 3rem

</style>
