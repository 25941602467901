import { handleError } from '@/lib/handleError'

export const encode = (payload, type) => {
  try {
    return (encoders[type] && encoders[type](payload)) ?? payload
  } catch (err) {
    console.warn('payload', payload)
    console.warn('type', type)
    handleError(`decode error: ${err.message ?? err}`)
  }
}
export const decode = (content, type) => {
  try {
    return (decoders[type] && decoders[type](content)) ?? content
  } catch (err) {
    console.warn('content', content)
    console.warn('type', type)
    handleError(`encode error: ${err.message ?? err}`)
  }
}

const encoders = {
  json: payload => JSON
    .stringify(payload)
    .replaceAll(/\\/g, '\\\\')
    .replaceAll(/"/g, '\\"'),
}

const decoders = {
  json: content => JSON.parse(
    content
      .replaceAll(/\\"/g, '"')
      .replaceAll(/\\\\/g, '\\')
  )
}
