<template>
  <div class="draggable uk-background-muted">
    <IconDrag></IconDrag>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableListItem"
}
</script>

<style scoped lang="stylus">
  .draggable
    display grid
    grid-template-columns 1rem 1fr
    grid-column-gap .5rem
    cursor ns-resize
    *
      display flex
      align-items center

</style>
