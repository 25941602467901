<template>
  <component
      v-if="document && document.id"
      :is="componentName"
      :collection-id="collectionId"
      :document-id="document.repoId"
      :removeBodyPaddingLeft="removeBodyPaddingLeft"
  ></component>
</template>

<script>
import Project from "@/components/site/projects/Project";
import PersonCard from "@/components/site/about/PersonCard";
import Publication from "@c/site/publications/Publication";
import Testimonial from "@/components/site/testimonials/Testimonial"
import CenterService from "@/components/site/centerServices/CenterService"
import CHCAAUCloudService from "@/components/CHCAA/CHCAAUCloudService"
import ClickToReveal from "@c/utilities/paragraphComponents/ClickToReveal"
import Example from "@c/utilities/paragraphComponents/Example"
import Hint from "@c/utilities/paragraphComponents/Hint"
import Resource from "@/components/site/resources/Resource"
import MarkdownContent from "@/components/service/document/md/MarkdownContent";
import MarkdownColumns from "@/components/service/document/md/columnDocuments/MarkdownColumns";
import {useDocument} from '@/components/use/serviceAssets/useDocument'
import Course from "@c/learningMaterials/course/Course";
import Module from "@/components/learningMaterials/course/courseModule/Module";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'DocumentLayout',
  components: {
    CenterService,
    CHCAAUCloudService,
    ClickToReveal,
    Course,
    Example,
    Hint,
    MarkdownContent,
    MarkdownColumns,
    Module,
    PersonCard,
    Project,
    Publication,
    Resource,
    Testimonial,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
    },
    documentName: {
      type: String,
    },
    removeBodyPaddingLeft: {
      type: Boolean,
      default: false
    }

  },
  setup(props) {
    const store = useStore()
    const {getDataType, loadDocument} = useDocument(props)
    if (!props.documentId) loadDocument({
      collectionId: props.collectionId,
      documentId: props.documentId,
      documentName: props.documentName,
    }, 'DocumentLayout')
    const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))
    const dataType = computed(() => getDataType(props.documentId))
    const componentName = computed(() => {
      return {
        PublicationCard: 'Publication',
        CourseCard: 'Course',
        ModuleCard: 'Module',
      }[dataType.value] ?? dataType.value
    })
    return {
      componentName,
      document,
      dataType,
    }
  }
}
</script>

<style scoped>

</style>
