<template>
  <div class="actions">
    <div
        v-for="(config, index) in columnVariants"
        :key=" index"
        class="uk-button uk-button-primary"
        @click="createColumnDocument(config.columns)"
        v-html="config.label"
    ></div>
  </div>
</template>

<script>
import generateRandomString from "@/lib/generateRandomString";
import {useCollection} from "@/components/use/serviceAssets/useCollection";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "CreateCollectionColumnsDocument",
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentEncoding: {
      type: String,
      default: 'markdownArray'
    },
    dataType: {
      type: String,
      default: 'MarkdownColumns',
    },
    columnVariants: {
      /*
        // todo: settings that allow 2-1 and 1-2 layouts
       */
      type: Array,
      default: () => [{label: 'Full-width document', columns: 1}]
    },
  },
  setup(props) {
    const store = useStore()
    const { createCollectionDocument} = useCollection()
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const getDefaultContent = columns => '# column N'
        .repeat(columns)
        .split('N')
        .filter(string => string)
        .map((string, index) => string + (index + 1))

    const createColumnDocument = columns => createCollectionDocument({
      serviceId: store.state.serviceName,
      collectionId: props.collectionId,
      documentName: generateRandomString(),
      dataType: props.dataType,
      encoding: props.documentEncoding,
      content: getDefaultContent(columns),
    })

    return {
      createColumnDocument,
      collection,
    }
  }
}
</script>

<style scoped>

</style>
