<template>
  <IconTrashCanOutline @click="showUKModal"></IconTrashCanOutline>
  <div
      id="modal-delete-document"
      ref="modal"
      uk-modal
      @click="hideUKModal"
  >
    <div
        ref="dialog"
        class="uk-modal-dialog uk-margin-auto-vertical"
        @click.stop
    >
      <button
          class="uk-modal-close-default"
          type="button"
          @click="hideUKModal"
          uk-close
      ></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">
          Confirm to Delete this Document
        </h2>
      </div>
      <div class="uk-modal-body">
        <p>
          This action is irreversible.
          <span v-if="elaboration">{{elaboration}}</span>
          Please type '{{ requiredInputValue }}' to confirm.
        </p>
        <p v-if="showTimer">
          This action will auto-abort in {{ time }} seconds
        </p>
        <br v-else>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <vTextInput
            v-if="!actionEnabled"
            ref="input"
            v-model="reply"
            :disabled="actionEnabled"
            :placeholder="requiredInputValue"
            class="confirmationInput"
            type="text"
            @blur="checkReply"
            @keyup="checkReply"
            @keyup.esc="resetUI"
        />
        <div
            v-if="actionEnabled"
            class="action icon4x"
            @click="emitActionConfirmed"
        >
          <IconTrashCanOutline
              class="actionButton danger"
              label="Delete Document"
              title="Delete Document"
          >
          </IconTrashCanOutline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useTimer from "@/components/use/useTimer";
import {computed, ref} from "vue";

export default {
  name: 'DeleteDocumentDialog',
  emits: ['closing-delete-document-dialog', 'delete-document-aborted', 'delete-document-confirmed'],
  props: {
    requiredInputValue: {
      type: String,
      default: 'delete'
    },
    timerSeconds: {
      type: Number,
      default: 20,
      validator(val) {
        return val > 5
      }
    },
    elaboration: {
      type: String,
      default: "Confirming this action will delete this document."
    },
    tipPosition: {
      type: String,
      default: 'bottom'
    },
    tipAlignment: {
      type: String,
      default: 'center'
    }
  },
  setup(props, {emit}) {
    const actionEnabled = ref(false)
    const showTimer = ref(true)
    const seconds = ref(props.timerSeconds)
    const {
      cancelTimer,
      resetTimer,
      secondsRemaining,
      setTimer,
    } = useTimer(props)

    const time = computed(() => {
      return secondsRemaining.value
    })

    // UI states
    const reply = ref('')
    const invalidMessage = ref('')
    const resetUI = () => {
      showTimer.value = true
      reply.value = ''
      actionEnabled.value = false
      setTimer(seconds.value, autoAbort)
    }
    const settleUI = () => {
      invalidMessage.value = ''
      reply.value = ''
      cancelTimer()
      showTimer.value = false
    }
    const autoAbort = () => {
      emit('delete-document-aborted')
      reply.value = ''
      invalidMessage.value = ''
      actionEnabled.value = false
      hideUKModal()
    }

    // confirmation
    const checkReply = event => {
      const inputIsCorrect = event.target.value === props.requiredInputValue
      if (inputIsCorrect) confirmAction()
      else resetTimer(resetUI)
    }
    const confirmAction = () => {
      settleUI()
      actionEnabled.value = true
    }

    // modal window states
    const modal = ref(null)
    const dialog = ref(null)
    const showUKModal = () => {
      modal.value.classList.add('uk-open')
      modal.value.style.display = 'block'
      dialog.value.style.display = 'block'
      resetUI()
    }
    const hideUKModal = () => {
      emit('closing-delete-document-dialog')
      modal.value.classList.remove('uk-open')
      modal.value.style.display = 'none'
      dialog.value.style.display = ''
    }

    // emits
    const emitActionConfirmed = () => {
      emit('delete-document-confirmed')
      resetUI()
      hideUKModal()
    }

    return {
      actionEnabled,
      checkReply,
      dialog,
      emitActionConfirmed,
      hideUKModal,
      modal,
      reply,
      resetUI,
      secondsRemaining,
      showUKModal,
      showTimer,
      time,
    }
  },
}
</script>

<style lang="stylus" scoped>
.deleteDocumentButton
  font-size 1rem

.confirmationInput
  font-size 2rem

.action {
  display flex
  justify-content center
}
</style>
